<template>
  <CFooter :fixed="false">
    <div>
      <span class="ml-1">ENGIE ENSEMBLE OneCloud</span>
    </div>
    <div class="mfs-auto">
      <span class="mr-1">Version {{ $store.getters.version}}</span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
