<template>
  <div class="c-app">
    <TheSidebar :currentAccount="currentAccount" />
    <CWrapper>
      <TheHeader />
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <transition name="fade" mode="out-in">
              <router-view :key="$route.path"></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
      <div>
        <TheProcessingModal :show="$store.state.busymodalShow" />
      </div>
      <div>
        <ErrorModal />
      </div>
      <TheFooter />
    </CWrapper>
  </div>
</template>

<script>
import TheSidebar from "./TheSidebar";
import TheHeader from "./TheHeader";
import TheFooter from "./TheFooter";
import TheProcessingModal from "./TheProcessingModal";
import ErrorModal from "./ErrorModal";

export default {
  name: "TheContainer",
  components: {
    TheSidebar,
    TheHeader,
    TheFooter,
    TheProcessingModal,
    ErrorModal
  },
  computed: {
    currentAccount() {
      return this.$root.currentAccount;
    }
  },

  async created() {
    var accessToken = await this.$auth.getAccessToken();
    if (
      this.$store.getters["portalParametersModule/accountWorkInProgress"] ===
      undefined
    ) {
      await this.$store.dispatch("portalParametersModule/loadWipParameters", {
        refresh: true,
        accessToken: accessToken
      });
    }
    if (!this.$store.getters["portalParametersModule/accountWorkInProgress"]) {
      this.$permissionInit();
    }

    // const data = this.$store.getters["portalParametersModule/workInProgress"];
    console.log(await this.$auth.getAccessToken());
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
