<template>
    <div>
      <CModal
      :title="$store.state.error.title"
      :show.sync="$store.state.errormodalShow"
      size="xl"
      :closeOnBackdrop="false"
      color="danger"
      >
      {{$store.state.error.message}}
      <template #footer>
        <CButton @click="dispose()" color="danger">OK</CButton>
      </template>        
      </CModal>
    </div>
</template>

<script>
export default {
  name: 'errorModal',
  methods: {
    dispose() {
      this.$store.commit('set', ['errormodalShow', false])
    }
  }
}
</script>