<template>
    <div>
      <CModal
      title="Processing ..."
      :show.sync="show"
      size="xl"
      :closeOnBackdrop="false"
      >
      <div align="center">
        <img src="/img/tenor.gif" width="50"/>
      </div>
      <template #header>
        <h5>Processing ...</h5>
      </template>
      <template #footer>
        <h5></h5>
      </template>      
      </CModal>
    </div>
</template>

<script>

export default {
  name: 'TheProcessingModal',
  props: {
      show: Boolean
  }
}
</script>